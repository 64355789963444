import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { Link } from "@chakra-ui/next-js";
import Head from "next/head";

const Page404 = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100vh"
      rowGap="4"
      px="4"
    >
      <Head>
        <title>Not Found</title>
      </Head>
      <Heading>404 - Page not found!</Heading>
      <Text textAlign="center">
        Sorry, the page you are looking for does not exist.
      </Text>
      <Link href="/" bg="#8DEBCF" p="2" rounded="md" fontWeight="bold">
        Click here to go Home
      </Link>
    </Flex>
  );
};

export default Page404;
